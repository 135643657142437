.homebase {
    display: grid;
    grid-template-rows: 90px 1fr 6fr 20px;
    width: 100%;
    margin: auto;
    /* align-items: center; */
    margin-bottom: 10px;
    min-height: 95vh;
    /* background-color: red; */
}

.centered-h2{
    text-align: center;
    margin: auto;
}
.listgroup-dark{
    /* background-color: rgb(47, 47, 47); */

}
.AppBase{
    display: grid;
    grid-template-columns: 1fr, 3fr;
}

.videoSize{
    /* height: 100%; */
    /* width: 99%; */
    /* display: flex; */
    /* justify-content: center; */
}
.vp-video-wrapper{
    /* min-width: 600px; */
    background-color: white;
    height: auto;
}


.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  border: 1px solid black;
  /* width: 95%; */
  /* height: auto; */
}
.definition{
    font-weight: bold;
}

option{
    margin: 10px 0;
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
/* .player-wrapper {
    width: auto; 
    height: auto;
  }
  .react-player {
    padding-top: 56.25%; 
    position: relative; 
  }
  
  .react-player > div {
    position: absolute; 
  } */
.vp-player-ui-container{
    width: 100%;
}
h1{
    text-align: center;
}
button {
    min-width: 60px;
    /* color: white; */
    background-color: white;
    padding: 10px;
    font-weight: 700;
    margin: 2px;
    border: grey 1px solid;
}

p {
    margin-top: 15px;
}
.navigation{
    width: 60vw;
    display: flex;
    justify-items: center;
    margin: auto;
    text-align: center;
}
.btn-text{
    font-weight: normal;

}
ul {
    display: inline;
}
.footer{
text-align: center;
padding:0;
margin: 2px;
}
li {
    display: inline;
    /* padding: 5px 20px; */
    /* margin: 10px; */
}

@media only screen and (max-width: 600px) {
    body {
      /* background-color: rgb(247, 0, 0); */
      /* color: white; */
      font-weight: 700;

    }
.videoSize>div{
    max-width: 99% !important;
    margin: 0;
}
    .homebase {
        padding-top: 15px;
        display: grid;
        grid-template-rows: 50px 1fr;
        width: 90vh;
        margin: auto;
        align-items: center;
    }
    p {
        margin-top: 20px;
    }
    
    button {
        min-width: 60px;
        /* margin-bottom: 15px; */
    }
    
    
    ul {
        display: inline;
    }
    
    li {
        display: inline;
        /* padding: 5px 20px; */
    }
  }

